.article-img {
  width: 15%;
}

.article-text h2 {
  text-align: left;
  padding-top: 1%;
  padding-bottom: 1%;
  font-family: 'KanedaGothic-Bold';
}

.page--our-project .logo {
  // max-width: 20%;
  object-fit: cover;
  width: 140px; /* Set the desired width */
  height: auto; /* Set the desired height */
  overflow: hidden;
  bottom: 0; /* Align the image to the bottom */
  right: 0; /* Align the image to the right */
  position: absolute;
  margin-bottom: 3%;
}

.row h2 {
  text-align: left;
  color: #102363;
}
