*,
*:before,
*:after {
  box-sizing: $univSize;
  margin: $baseValue;
  padding: $baseValue;
}

p a,
ul a,
ol a {
  color: #ff9067 !important;
}

p a:hover,
ul a:hover,
ol a:hover {
  color: #102363 !important;
}

html {
  scroll-behavior: smooth;
}

.my-5 {
  margin-left: 0%;
}

.row {
  margin-right: 0;
  margin-left: 0;
}

hr {
  margin: 0rem 0 !important;
}

h2 {
  font-size: $casestudy-font !important;
}

.react-reveal {
  padding-right: 0;
  padding-left: 0;
}
