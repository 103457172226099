@font-face {
  font-family: 'KanedaGothic-Bold';
  src: url('../../fonts/KanedaGothic-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../fonts/Nunito-Regular.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

.subheads {
  font-family: 'KanedaGothic-Bold';
  line-height: $line-height-exsmall;
}

.vp-center {
  height: auto;
}

.generic-lineheight {
  line-height: $line-height-large;
}

.no-strech {
  display: $unset;
}

iframe {
  display: block;
  margin: 0 auto;
}

span a {
  color: #6fe9fe !important;
}

span a:hover {
  color: white !important;
}

.nav-link:hover {
  color: #6fe9fe !important;
}

.vimeo-response {
  padding: 2%;
}

.iframe {
  margin: 0;
}

.testimonial-width {
  width: $w-8;
}

.block-case-study * {
  font-size: 1rem;
}

.spacer {
  padding-left: 1rem;
  padding-right: 1rem;
}

.border-right {
  padding-right: 1rem;
}

.grid-image {
  border: 0 none;
  height: 60px;
  margin: 5%;
  justify-content: center;
  background-color: transparent;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

.bg-yellow {
  background-color: #f5f1e7;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 1%,
    rgb(0, 0, 0) 18%
  );
}

.generic-padding-top {
  padding-top: $mandatory-pad;
}

.block-case-study {
  font-size: $standard-text;
}

.offset {
  margin-left: 20rem;
  margin-top: 6rem;
}

.generic-padding-bottom {
  padding-bottom: $mandatory-pad;
}

.mid-lineheight {
  line-height: $line-height-mid;
}
body h1 {
  text-transform: uppercase;
  color: #102363;
}

.top-nav {
  .btn-outline-primary {
    border-color: #8fe7fb !important;
    color: #102363 !important;
    background-color: #8fe7fb;
  }
}

.btn:hover {
  background-color: white;
  color: #0d6efd;
  border: black 1px solid;
}

.container-fluid {
  padding-left: 0;
  overflow-x: hidden;
  padding-right: 0;
}

.hero-image {
  // background-image: url('path/to/your-hero-image.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.btn-outline-primary {
  color: #333333;
  border-color: #333333;
}

.text-large {
  font-size: $text-large;
}

.text {
  font-size: $text;
}

.text ul {
  font-size: $mid-size;
}

.headerandtext {
  // padding-top: 10% !important;
  // padding-bottom: 10% !important;
}

.block--testimonial * {
  color: #1427bf;
}

.img-thumbnail {
  border: 0 none;
  width: 85px;
  background-color: transparent;
}
.btn {
  text-transform: uppercase;
}

.gray-bg {
  background-color: #f0f0f0;
}

.card {
  border: none;
  background-color: transparent;
}

.sticky-right {
  position: sticky;
  right: 0;
}

.block-article a {
  color: #333;
  font-weight: bold;
}

.border-right {
  border-right: 1px solid gray;
}

.recent-posts ul li {
  list-style: none;
}
.recent-posts a.small {
  color: #333;
  text-decoration: underline !important;
}

/*
 * Blocks
 */
.block-case-study * {
  color: white;
}
.block-case-study {
  .btn {
    border: 1px solid white;
    &:hover {
      background-color: white;
      color: black;
    }
  }
}

.block--header-and-text .white-text * {
  color: white;
}

.block--thumbnails-list .img-thumbnail {
  max-width: 100px;
  width: auto;
}

.block--horizontal-divider hr {
  height: 2px;
  background-color: #000;
  margin-top: 10px auto;
}

.block--list-of-tags {
  padding: 2% 0% 0% 0%;
  text-align: $univAlignment;
  // background-color: $sectionback-color;
  .separator {
    padding: 0 8px;
  }
}

.page--our-project .logo {
  object-fit: cover;
  width: 130px !important;
  height: auto;
  overflow: hidden;
  bottom: 0;
  right: 0;
  position: absolute;
  margin-bottom: 3%;
}

.block--latest-projects {
  padding-top: $mandatory-pad;
  padding-bottom: $mandatory-pad + 2%;
  background-color: $sectionback-color;
  h4 {
    margin-bottom: 0;
  }
  .text-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .inner-wrapper {
    padding-bottom: 60px;
  }
}

.g-flex {
  display: flex !important;
}

.block--commitment,
.clientlogos {
  padding-top: $mandatory-pad + 2%;
  padding-bottom: $mandatory-pad + 2%;
}

.houseofwaris {
  width: 100%;
  margin: 0 auto;
}

.testtimonial-text {
  font-size: $bluesection-textsize;
}

figure img {
  object-fit: cover;
  max-height: 100%;
  max-width: 100%;
}

.block--wide-text h3 {
  font-weight: normal;
  padding-top: $mandatory-pad + 2%;
  p {
    margin-bottom: 0;
  }
}

.commitment-onecol {
  padding-top: 2%;
}

.btn-arrow {
  background-image: url('#/media/arrow-right.png');
  height: 17px;
  width: 55px;
}

.gray-section {
  padding-top: 4%;
  padding-bottom: 2%;
}

.top-gradient {
  /* ff 3.6+ */
  background: -moz-linear-gradient(
    90deg,
    rgba(249, 158, 249, 1) 25%,
    rgba(0, 209, 255, 1) 81%
  );
  /* safari 5.1+,chrome 10+ */
  background: -webkit-linear-gradient(
    90deg,
    rgba(249, 158, 249, 1) 25%,
    rgba(0, 209, 255, 1) 81%
  );
  /* opera 11.10+ */
  background: -o-linear-gradient(
    90deg,
    rgba(249, 158, 249, 1) 25%,
    rgba(0, 209, 255, 1) 81%
  );
  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00D1FF', endColorstr='#F99EF9', GradientType=0 );
  /* ie 10+ */
  background: -ms-linear-gradient(
    90deg,
    rgba(249, 158, 249, 1) 25%,
    rgba(0, 209, 255, 1) 81%
  );
  /* global 94%+ browsers support */
  background: linear-gradient(
    90deg,
    rgba(249, 158, 249, 1) 25%,
    rgba(0, 209, 255, 1) 81%
  );
  height: 4px;
}

.page--home {
  .hero-image1 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url('#/media/Hero-Banner-Home-Page.png');
    /* Set a specific height */
    height: 400px;
    /* Position and center the image to scale nicely on all screens */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  .hero-image .block--list-of-tags {
    padding-left: 0;
    padding-right: 0;
  }
  .hero-image * {
    color: white;
  }
  .stats-numbers {
    color: $stats-color;
    font-weight: 600;
    font-family: 'KanedaGothic-Bold';
    font-size: 5.625rem;
  }
}

.button-pads {
  margin: 2%;
}

.block--project-tile * {
  color: #333;
}
.block--project-tile .btn-arrow {
  width: 100%;
  height: auto;
  background-position: right;
  background-repeat: no-repeat;
}

.page--article .img-thumbnail {
  width: 250px;
}

.page--latest-articles {
  .img-thumbnail {
    width: 250px;
  }
}

.generic-gradient {
  background: rgb(0, 209, 255);
  background: linear-gradient(
    90deg,
    rgba(0, 209, 255, 0.7567620798319328) 0%,
    rgba(249, 158, 249, 0.9836528361344538) 23%,
    rgba(0, 209, 255, 0.7567620798319328) 100%
  );
  -webkit-background-clip: text;
  font-weight: 600;
  font-size: 84px;
  background-size: 200% auto;
  -webkit-text-fill-color: transparent;

  animation: shine 3s linear infinite;
  @keyframes shine {
    to {
      background-position: 200% center;
    }
  }
}

.homebannercontent,
.tags {
  font-weight: 100;
  font-size: 16px;
}

.banner-text {
  padding: 6% !important;
  line-height: $line-height-large;
}

.proj-pads {
  padding: 5% !important;
}

.latest-proj-home-image {
  min-width: -webkit-fill-available;
}

.banner-text {
  margin-left: 12%;
  padding-top: 4%;
  width: 40%;
}

.grid-images,
.grid-images-partners {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.grid-images {
  padding: 10% 4%;
  width: 60%;
}

.grid-images-partners {
  width: 80%;
  padding: 4%;
}

.ClientImages {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 10px;
  padding-right: 10%;
  padding-left: 10%;
  margin-top: 2rem;

  .grid-item {
    grid-column: span 6;
    grid-row: 1;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  // Media query for ipad devices
  @include tablet {
    grid-template-columns: repeat(3, 1fr);

    .grid-item {
      grid-column: span 2;
      grid-row: span 2;
    }

    .grid-images {
      padding: 10%;
      width: 80%;
    }
  }

  // Media query for mobile devices
  @include mobile {
    grid-template-columns: repeat(2, 1fr);

    .grid-item {
      grid-column: span 1;
      grid-row: span 1;
    }
  }
}

.reverse-display {
  display: none;
}

// small devices surface pro and macbook
@media only screen and (max-width: 1380px) {
  .banner-text {
    width: 70%;
    margin-left: 0%;
  }
  .vimeo-response {
    padding: 2%;
  }
  .iframe {
    margin: 0;
  }
  .subheads {
    font-size: 5rem;
  }
  .block--list-of-tags {
    padding: 3%;
  }
  .mb-casestudy {
    background-size: cover !important;
    height: 48rem !important;
    background-repeat: no-repeat;
  }
  .offset {
    margin: 40px;
    font-size: 1rem;
    padding-top: 3rem;
  }
  .display {
    display: none;
  }
  .reverse-display {
    display: block;
    padding-top: 1% !important;
    margin-top: 0%;
    color: #f0f0f0;
  }
  .page--our-project .logo {
    object-fit: cover;
    width: 85px !important;
    height: auto;
    overflow: hidden;
    bottom: 0;
    right: 0;
    position: absolute;
    margin-bottom: 3%;
  }
}

@media only screen and (max-width: 1000px) {
  .three-col-footer {
    width: 30%;
    justify-content: center !important;
  }
  .justify-content-end {
    justify-content: center !important;
  }
  .block--testimonial {
    padding-top: 14%;
  }
  .g-flex {
    flex: 1 0 20%;
    margin-bottom: 2%;
    padding: 2%;
    margin-top: 2%;
  }
  .display {
    display: none;
  }
  .reverse-display {
    display: block;
    padding-top: 5% !important;
    margin-top: 0%;
    color: #f0f0f0;
  }
  .fcol {
    height: 50vh;
  }
  .mb-casestudy {
    background-size: cover !important;
    height: 35rem !important;
    background-repeat: no-repeat;
  }
  .page--our-project .logo {
    object-fit: cover;
    width: 85px !important;
    height: auto;
    overflow: hidden;
    bottom: 0;
    right: 0;
    position: absolute;
    margin-bottom: 3%;
  }
  .vimeo-response {
    padding: 2%;
  }
  .iframe {
    margin: 0;
  }
}

// ipad devices
@media only screen and (max-width: 820px) {
  .subheads {
    font-size: 3rem;
    line-height: 0.9;
  }
  .banner-text {
    width: 70%;
    margin-left: 0%;
    line-height: $line-height-mid;
  }
  .homebannercontent {
    font-size: 0.9rem;
  }
  .hero-image {
    background-position: right 50% bottom 45%;
  }
  .block--list-of-tags {
    font-size: $mob-font;
    padding: 4%;
  }
  .block--testimonial {
    padding-top: 14%;
  }
  .mb-casestudy {
    background-size: cover !important;
    height: 26rem !important;
    background-repeat: no-repeat;
  }
  .offset {
    margin: 0px;
    font-size: 1rem;
    margin-top: 8rem;
  }
  .display {
    display: none;
  }
  .reverse-display {
    display: block;
    padding-top: 5% !important;
    margin-top: 0%;
    color: #f0f0f0;
  }
  .border-right {
    border: 0;
    padding-right: 0rem;
  }
  .fcol {
    height: 44vh;
  }
  .page--our-project .logo {
    object-fit: cover;
    width: 85px !important;
    height: auto;
    overflow: hidden;
    bottom: 0;
    right: 0;
    position: absolute;
    margin-bottom: 3%;
  }
  .vimeo-response {
    padding: 2%;
  }
  .iframe {
    margin: 0;
  }
}

// mobile devices
@media only screen and (max-width: 600px) {
  .banner-text {
    width: 80%;
    margin-left: 0%;
    line-height: $line-height-medium;
  }
  .vimeo-response {
    padding: 2%;
  }
  .iframe {
    margin: 0;
  }
  .article-img {
    width: 25% !important;
  }
  footer {
    padding: 10%;
  }
  .three-col-footer {
    width: 70%;
    justify-content: center !important;
  }
  .justify-content-end {
    justify-content: center !important;
  }
  .align-items-end {
    justify-content: left !important;
  }
  .d-flex {
    display: block !important;
    word-break: break-word;
  }
  .footer-header {
    font-size: $standard-text + 1rem;
  }
  .footer-text {
    font-size: $standard-text;
    padding-top: $mandatory-pad;
  }
  .lp-proj {
    padding: 6%;
  }
  .houseofwaris {
    padding-bottom: 12%;
  }
  .m-view {
    padding-top: 0 !important;
  }
  .block--testimonial {
    padding-top: 2%;
    padding-bottom: 4%;
  }
  .g-flex {
    flex: 1 0 50%;
    padding: 4%;
  }
  .block--list-of-tags {
    font-size: $mob-font;
    padding-left: 4%;
    padding-top: 8%;
    padding-right: 4%;
  }
  .text-align {
    display: flex;
    justify-content: center;
  }
  .test {
    display: flex;
    flex-direction: row-reverse;
  }
  .block--latest-projects .inner-wrapper {
    padding-bottom: 0px;
  }
  .mb-casestudy {
    background-size: cover !important;
    height: 17rem !important;
    background-repeat: no-repeat;
  }
  .block-case-study {
    font-size: $bluesection-textsize;
  }
  .offset {
    margin: 0px;
    font-size: 1rem;
    margin-top: 1rem;
  }
  .xs-pad {
    padding-top: 10%;
  }
  .navbar {
    padding: 6% 0;
  }
  .text-large {
    font-size: $bluesection-textsize;
  }
  .row h2 {
    font-size: 1.4rem !important;
    font-weight: 500;
  }
  .display {
    display: none;
  }
  .reverse-display {
    display: block;
    margin-top: 0%;
    color: #f0f0f0;
  }
  .border-right {
    border: 0;
    padding-right: 0rem;
  }
  .article-text h2 {
    font-size: $casestudy-font !important;
  }
  iframe {
    width: 500;
  }
  .h-horizontal {
    display: block !important;
    margin-top: 1rem !important;
    margin-bottom: 2rem !important;
  }
  .fcol {
    height: 30vh;
  }
  .figcol {
    height: 50vh;
  }
  p,
  span,
  ul li,
  ol li,
  h6 {
    font-size: 0.9rem;
  }
  .page--our-project .logo {
    object-fit: cover;
    width: 65px !important;
    height: auto;
    overflow: hidden;
    bottom: 0;
    right: 0;
    position: absolute;
    margin-bottom: 3%;
  }
}

@media only screen and (max-width: 300px) {
  .fcol {
    height: 40vh;
  }
  .vimeo-response {
    padding: 2%;
  }
  .iframe {
    margin: 0;
  }
  .reverse-display {
    display: block;
    padding-top: 100% !important;
    margin-top: -120%;
    color: #f0f0f0;
  }
}

.h-horizontal {
  display: none;
}

table {
  min-width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
}

table td,
table th {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  overflow: hidden;
  width: 50%;
}

table img {
  display: block;
  float: left;
  clear: both;
  max-width: 100%;
  height: auto;
}

@media (max-width: 640px) {
  table td,
  table th {
    display: block;
    width: 100%;
  }
}
