body,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $baseColor;
}

.bannersubtext {
  color: $secondaryColor;
}

a.link:hover,
.link:hover,
a.link:focus,
.link:focus {
}

a.link,
.link,
a.link:link,
.link:link,
a.link:visited,
.link:visited {
}

a,
a:link,
a:visited {
  text-decoration: none !important;
}

.pt-5 {
  padding-top: 0% !important;
}
