//family font
$baseFontFamily: Nunito-Regular;
$secondaryFontFamily: arial;

// Colors
$baseColor: Black;
$secondaryColor: white;
$primary-color: #102363;
$secondary-color: #6c757d;
$success-color: #28a745;
$danger-color: #dc3545;
$warning-color: #ffc107;
$info-color: #17a2b8;
$sectionback-color: #f0f0f0;
$dark-color: #343a40;
$color-btn: #6fe9fe;
$stats-color: #ff9067;

// Spacing
$spacer: 1rem;
$half-spacer: 0.5rem;
$quarter-spacer: 0.25rem;
$double-spacer: 2rem;
$triple-spacer: 3rem;

// Border radius
$border-radius: 0.25rem;

// Box shadows
$box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

// Z-indexes
$z-index-base: 100;
$z-index-modal: 1050;
$z-index-tooltip: 1070;
$z-index-popover: 1080;
$z-index-navbar: 1090;

// Other variables
$container-max-width: 1200px;
$form-control-height: 38px;
$form-control-font-size: 16px;
$sectionHeadings: 40px;
$sectionHeadingBaseweights: 400;
$baseValue: 0;
$univAlignment: center;
$univSize: border-box;
$unset: unset;

//line height
$line-height-exsmall: 0.9;
$line-height-small: 1.2;
$line-height-medium: 1.4;
$line-height-mid: 1.8;
$line-height-large: 2;

//generic section padding
$mandatory-pad: 2%;

//font-size
$bluesection-textsize: 1.125rem;
$standard-text: 1.25rem;
$subheads-fontsize: 5rem;
$miniheads-fontsize: 1.375rem;
$mob-font: 0.9rem;
$casestudy-font: 2rem;
$text-large: 1.5rem;
$text: 1.625rem;
$mid-size: 1.3rem;

//font-weight
$f-1: 100;
$f-2: 200;
$f-3: 300;
$f-4: 400;
$f-5: 500;
$f-6: 600;
$f-7: 700;
$f-8: 800;
$f-9: 900;

//width
$w-1: 10%;
$w-2: 20%;
$w-3: 30%;
$w-4: 40%;
$w-5: 50%;
$w-6: 60%;
$w-7: 70%;
$w-8: 80%;
$w-9: 90%;
