// body {
//     font-family: $baseFontFamily;
// }

h2 {
  font-size: $sectionHeadings;
  font-weight: $sectionHeadingBaseweights;
  text-align: $univAlignment;
  font-family: nunito;
}

h1 {
  font-size: $subheads-fontsize;
  font-family: nunito;
}

h3 {
  font-size: $miniheads-fontsize;
  font-family: nunito;
}

//  .block--latest-projects h4 {
//    font-family: nunito;
//  }

//  h4 {
//    font-family: nunito;
//  }

//  p span {
//    font-family: nunito;;
//  }

//  ul li, ol li {
//    font-family: nunito;
//  }

//  div p {
//    font-family: nunito;
//  }
