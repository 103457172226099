.footer * {
  color: $secondaryColor;
}

.footer hr {
  opacity: 1;
}

footer .btn {
  background-color: $color-btn;
  color: $primary-color;
  &:hover {
    background-color: $primary-color;
    color: $color-btn;
    border: 1px solid $color-btn;
  }
}
.top-nav .btn-outline-primary:hover {
  background-color: $primary-color;
  color: $color-btn !important;
  border: 1px solid $color-btn;
}

.navbar,
footer {
  background-color: $primary-color;
  line-height: $line-height-large;
  p {
    color: $secondaryColor;
  }
  .highlighted {
    color: $color-btn;
  }
}
.navbar-dark .navbar-nav .nav-link {
  color: $secondaryColor;
}

.navbar {
  padding: 1% 0;
}

footer {
  padding-top: $mandatory-pad + 4%;
  padding-bottom: $mandatory-pad;
}

.footer-header {
  font-size: $standard-text + 1.25rem;
  font-weight: $f-1;
}

.footer-text {
  font-size: $standard-text + 0.125rem;
  font-weight: $f-1;
  line-height: $line-height-medium !important;
}
